import React from "react";

function Faqs() {
  return (
    <>
      <div className="py-md-5 py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="gf_h2">
                Frequently
                <br />
                Asked Questions
              </h2>
              <p className="gf_text pt-2">
                About Our Digital Marketing, Web Design & Development Services
              </p>
            </div>
          </div>
        </div>
        <div className="container py-2">
          <div className="row align-items-center justify-content-center">
            {/* Left Column */}
            <div className="col-md-6 col-12">
              <div className="accordion accordion-flush" id="accordionFlushLeft">
                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      What services does Wodo Digital offer?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushLeft"
                  >
                    <div className="accordion-body">
                      Wodo Digital specializes in Digital Marketing, Web Design
                      & Development Services, including custom web design,
                      website development, SEO, PPC, social media management,
                      and more.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Why is custom web design important for my business?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushLeft"
                  >
                    <div className="accordion-body">
                      Custom web design ensures your website is unique, aligns
                      with your brand, and enhances user experience, which can
                      lead to higher engagement and conversions.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      How does Wodo Digital approach digital marketing?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushLeft"
                  >
                    <div className="accordion-body">
                      We use data-driven strategies tailored to your business
                      goals, focusing on SEO, PPC, content marketing, and social
                      media to drive traffic and increase conversions.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      What makes Wodo Digital different from other agencies?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushLeft"
                  >
                    <div className="accordion-body">
                      Wodo Digital combines creative design with technical
                      expertise, offering personalized solutions that are
                      innovative, scalable, and aligned with your business
                      needs.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6 col-12">
              <div className="accordion accordion-flush" id="accordionFlushRight">
                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      How can Wodo Digital help improve my website's performance?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushRight"
                  >
                    <div className="accordion-body">
                      We enhance your website's performance by optimizing
                      design, improving load times, and implementing effective
                      SEO strategies to boost your online visibility and user
                      engagement.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      What industries does Wodo Digital serve?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushRight"
                  >
                    <div className="accordion-body">
                      Wodo Digital provides services to a wide range of
                      industries, including e-commerce, healthcare, finance,
                      technology, and more, tailoring our approach to meet
                      specific industry needs.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      How can digital marketing services boost my business?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushRight"
                  >
                    <div className="accordion-body">
                      Digital marketing services, such as SEO and PPC, increase
                      your online visibility, attract more targeted traffic, and
                      ultimately lead to higher sales and business growth.
                    </div>
                  </div>
                </div>

                <div
                  className="accordion-item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    marginBottom: "15px",
                    transition: "all 0.3s ease", // Smooth transition
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      What is the process for working with Wodo Digital?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushRight"
                  >
                    <div className="accordion-body">
                      Our process involves understanding your goals, creating a
                      customized plan, executing the strategy, and providing
                      ongoing support and optimization to ensure success.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom styles for active accordion, hover, and description */}
      <style>
        {`
          /* Hover effect */
          .accordion-button:hover {
            background-color: #F8A925; /* Yellow color on hover */
            color: white;
          }

          /* Active accordion button color change */
          .accordion-button:not(.collapsed) {
            background-color: #F8A925; /* Change the active background to #F8A925 */
            color: white;
          }

          /* Remove border and blue outline when clicked */
          .accordion-button {
            border: none;
            box-shadow: none;
            font-weight: 600; /* Semi-bold title */
            transition: background-color 0.3s ease; /* Smooth background color transition */
          }

          /* Remove blue outline for focus */
          .accordion-button:focus {
            outline: none;
            box-shadow: none;
          }

          /* Accordion body description font weight */
          .accordion-body {
            font-weight: 300; /* Set the description font weight to 300 */
          }
        `}
      </style>
    </>
  );
}

export default Faqs;
